.gyneo {
  width: 90%;
  margin-left: 05%;
  display: flex;
  justify-content: center;
  align-items: center;
  height:auto; /* Adjust the height as per your layout */
  text-align: center;
  font-size: 28px; /* Font size for the headline */
  font-weight: bold;
  color: white; /* Text color */
  background-color: black; /* Background color */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted look */
  text-transform: uppercase; /* Optional: Make text uppercase */
  letter-spacing: 1px; /* Slightly increase letter spacing for readability */
}

.gyneo:hover {
  background-color: #e0e0e0; /* Lighten background color on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
  color: black;
}



.gynecology-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
}

.section {
  position: relative;
  width: 260px;
  height: 300px;
  margin: 20px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.4s ease;
}

.section:hover img {
  transform: scale(1.1);
}

.hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  transition: background-color 0.3s ease;
  border-radius: 10px;
}

.section:hover .hover-content {
  background-color: rgba(0, 0, 0, 0.5);
}

.hover-content h2 {
  font-size: 22px;
  text-align: center;
  z-index: 1; /* Ensure the text stays on top */
  margin: 0; /* Remove margins for better centering */
  text-transform: uppercase;
  margin-top: 70px;
  
  /* Text shadow for a lifted, glowing effect */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7), 
               0 0 10px rgba(255, 255, 255, 0.8);
}


/* ServicesPane.css */
/* Slider container */
.services-pane {
  padding: 20px;
  width: 100%;
  width: 98%;
}

/* Individual service card */
.service-card {
  padding: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

/* Service card image */
.service-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  max-height: 200px;
}

/* Service card info */
.service-info {
  padding: 10px 0;
}

/* Service card title */
.service-info h3 {
  font-size: 1em;
  margin-bottom: 10px;
  color: #333;
}

/* Service card description */
.service-info p {
  font-size: 0.9em;
  color: #666;
  text-align: justify;
}

/* Slick dots (pagination) */
.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #333;
}

.slick-dots li.slick-active button:before {
  color: #000;
}

/* Slider arrows */
.slick-prev, .slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  color: #000;
}

/* Mobile-specific adjustments */
@media screen and (max-width: 768px) {
  .service-card {
      padding: 5px;
      max-width: 100%;
  }

  .service-info h3 {
      font-size: 1em;
  }

  .service-info p {
      font-size: 0.85em;
  }
}



.whatsapp-btn {
  position: fixed;
  bottom: 05px;
  width: 50px;
  height: 50px;
  right: 20px;
  background-color: #25D366; /* WhatsApp green color */
  color: white;
  font-size: 28px;
  padding-top: 04px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a shadow for some depth */
  text-align: center;
  z-index: 1000;
  font-weight: bold;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-btn:hover {
  transform: scale(1.1); /* Slightly enlarges the button */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Adds a deeper shadow on hover */
}

.whatsapp-btn a {
  color: white;
  text-decoration: none;
}


.call-now-btn {
  position: fixed;
  bottom: 05px;
  width: 50px;
  height: 50px;
  left: 20px;
  font-weight: bold;
  background-color: #28a745; /* Call button green color */
  color: white;
  font-size: 27px;
  padding: 05px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  text-align: center;
  z-index: 1000;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.call-now-btn:hover {
  transform: scale(1.1); /* Slightly enlarges the button on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Adds a deeper shadow */
}

.call-now-btn a {
  color: white;
  text-decoration: none;
}


.image-video-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  padding: 20px;
  width: 100%;
}

.image-section, .video-section {
  width: 48%;
  height: 100%;
}

.image-section img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the container */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .image-video-container {
    flex-direction: column;
  }

  .image-section, .video-section {
    width: 100%;
    margin-bottom: 20px;
  }
}


.appointment-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.appointment-container:hover {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.appointment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-size: 1rem;
  color: #444;
}

input,
textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

input:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

textarea {
  height: 100px;
  resize: none;
}

.submit-button {
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.result-message {
  margin-top: 20px;
  font-size: 1.2rem;
  text-align: center;
  color: green;
}

@media (max-width: 768px) {
  .appointment-container {
    padding: 15px;
  }

  h2 {
    font-size: 1.5rem;
  }
}


.mission-container {
  background-image: url("../src/img/background.jpg"); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width: 100%;
}

.mission-content {
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  padding: 40px;
  border-radius: 10px;
  max-width: 1200px;
  max-height: 100vh;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.mission-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #ffdd57;
}

.mission-content p {
  font-size: 20px;
  margin-bottom: 40px;
  color: #ddd;
}

.mission-stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.stat-item {
  margin: 0px;
  text-align: center;
}

.stat-item h2 {
  font-size: 48px;
  margin: 0;
  color: #ffdd57;
}

.stat-item p {
  font-size: 17px;
  margin-top: 10px;
  text-align: justify;
}

@media (max-width: 768px) {
  .mission-content h1 {
    font-size: 36px;
  }

  .stat-item h2 {
    font-size: 36px;
  }

  .stat-item p {
    font-size: 18px;
  }
}



.reachus-container {
  background-image: url("../src/img/background.jpg"); /* Use your background image */
  background-size: cover;
  background-position: center;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 60px 20px; /* Added padding for better spacing */
}

.reachus-content {
  background: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
  padding: 40px;
  border-radius: 15px; /* More rounded corners */
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Deeper shadow for depth */
  transition: transform 0.3s; /* Smooth transition effect */
}

.reachus-content:hover {
  transform: scale(1.02); /* Slight zoom effect on hover */
}

.reachus-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #ffdd57;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Text shadow for better readability */
}

.reachus-content p {
  font-size: 20px;
  margin-bottom: 30px;
  color: #ddd;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
  color: white;
  text-align: left;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px; /* Increased padding for inputs */
  border-radius: 10px; /* More rounded corners */
  border: none;
  outline: none;
  font-size: 16px;
  transition: border-color 0.3s; /* Smooth border color transition */
}

.form-group input:focus,
.form-group textarea:focus {
  border: 2px solid #ffdd57; /* Highlight border on focus */
}

textarea {
  resize: vertical;
  height: 120px; /* Increased height for better usability */
}

.submit-btn {
  padding: 12px 20px;
  font-size: 18px;
  color: white;
  background-color: #ffdd57;
  border: none;
  border-radius: 10px; /* More rounded corners */
  cursor: pointer;
  transition: background 0.3s, transform 0.3s; /* Smooth transition effects */
}

.submit-btn:hover {
  background-color: #ffc107;
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.result-message {
  margin-top: 20px;
  font-size: 18px;
  color: #ffdd57; /* Highlight result message color */
}

@media (max-width: 768px) {
  .reachus-content h1 {
    font-size: 36px; /* Responsive heading size */
  }

  .form-group label {
    font-size: 16px; /* Responsive label size */
  }

  .submit-btn {
    font-size: 16px; /* Responsive button size */
  }
}
