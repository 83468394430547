.main {
  width: 100%;
  height: 100%;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: white;
  font-size: 15px;
  margin-left: 30px;
  height: 50px;
  font-weight: bold;
 
}

.navbar-brand b {
  color: white;
}

.navigate{
  background:  #d82256;
  position: sticky;
  top: 0;
  z-index: 999;
  border-radius: 10px;
  margin-top: 04px;
}

.navbar {


  width: 98%;
  margin-left: 13px;
  border-radius: 10px;
}




.carousel-item img {
  height: 450px;
}

.sections {
  width: 90%;
  height: 300px;
  margin-left: 80px;
  display: flex;
  margin-top: 04px;
}

.sect2 {
  width: 30%;
  height: 100%;

}

.sect2 img {
  width: 200px;
  height: 200px;
  margin-left: 140px;
  margin-top: 40px;
}

.sect3 {
  width: 70%;
  height: 100%;

}

.sect3 h1 {
  color: #ff86ad;
  font-weight: bold;
  font-size: 25px;
  margin-top: 40px;
  font-style: italic;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  max-width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}



.about {
  width: 90%;
  height: 500px;
  margin-left: 50px;
  display: flex;
  margin-top: 50px;
}

.about1 {
  width: 40%;
 
  height: 100%;
}

.about1 img {
  width: 92%;
  height: 90%;
  padding-left: 20px;
  margin-top: 20px;
  border-radius: 16px;
}

.about2 {
  width: 60%;
  height: 100%;
}

.about2 h1 {
  margin-left: 120px;
  font-size: 25px;
}

.about2 h2 {
  margin-left: 0px;
  font-size: 19px;
  margin-top: 10px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.about2 h3 {
  margin-left: 120px;
  font-size: 22px;
}

.about2 p {
  margin-left: 120px;
  font-size: 16px;
  color: black;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.about2 button {
  width: 150px;
  height: 45px;
  font-weight: bold;
  background: linear-gradient(90deg, #0078bf, #5a35aa, #b32491, #d82256);
  border: none;
  margin-left: 150px;
  border-radius: 10px;
  margin-top: 30px;
}

.about2 button:hover {
  background-color: #5e4e6d;
  color: white;
}

.treatment {
  width: 100%;
  height: auto;
  background-color: #ff86ad;
  margin-top: 20px;
}

.treat {
  width: 60%;
  height: 100%;
  text-align: center;
  margin-left: 250px;
  color: white;
}

.treat h1 {
  padding-top: 20px;
}

.obstic {
  width: 80%;
  height: auto;

  margin-left: 120px;
  margin-top: 30px;
  display: flex;
}

.obstic2 {
  width: 50%;
  height: 100%;

}

.facs {
  width: 100%;
  height: 150px;

}

.facs h1 {
  margin-left: 120px;
  font-size: 22px;
  color: white;
  margin-top: 20px;
}



.facs h1:hover{
  color: black;
  font-size: 25px;
  font-weight: bold;
}

.facs p {
  font-size: 17px;
  color: white;
  margin-left: 120px;
  margin-top: -50px;
}

.facs p:hover{
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.facs img {
  margin-top: -40px;
}

.content {
  width: 95%;
  height: 470px;
  display: flex;
  margin-left: 40px;
}

.content1 {
  width: 30%;
  height: 100%;

}

.content2 {
  width: 68%;
  height: 100%;
  margin-left: 15px;
  background-image: url(img/moms.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.melo {
  width: 450px;
  height: 100%;
}

.melo h1 {
  font-size: 20px;
  text-align: center;
  color: white;
  margin-top: 20px;
}

.melo p {
  color: white;
  font-size: 20px;
  margin-top: 30px;
  padding-left: 10px;
  text-shadow: 5px 5px 4px gray;
}

.content1 {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
select {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}

button:hover {
  background-color: #0056b3;
}

input[type="text"]:focus,
input[type="email"]:focus,
select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}
.frequently{
  width: 100%;
  height: 100%;
  background-color: lightblue;
}
.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 20px;
  margin-top: 10px;
}

.faq-item h3 {
  font-size: 1.2em;
  color: lightcoral;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.faq-item p {
  font-size: 1em;
  color:black ;
  font-weight: bold;
}


.footer {
  width: 100%;
  height: 430px;
  background-color: lightgray;
}

.footer h1 {

  color: black;
  font-size: 25px;
  margin-top: 20px;
}

.footer1 {
  width: 100%;
  height: 350px;
  display: flex;
  padding-top: 30px;
}

.foot {
  width: 25%;
  height: 100%;
  color: black;
}

.foot h1 {
  font-size: 22px;
  margin-left: 40px;
}

.link {
  color: black;
  text-decoration: none;
  font-size: 18px;
  margin-top: 20px;
}

.foot p {
  margin-left: 20px;
  margin-top: 20px;
  font-size: 16px;
  text-align: justify;
  width: 80%;
}

.disclame {
  width: 100%;
  height: 40px;
  background-color: #ff86ad;
}

.disclame p {
  text-align: center;
  padding-top: 10px;
  color: white;
}

.heading {
  width: 100%;
  height: 150px;
  background-image: url(img/about-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.heading h1 {
  text-align: center;
  padding-top: 30px;
  color: white;
}

.heading p {
  text-align: center;
  color: white;
}

.contat {
  width: 90%;
  height: 480px;
  display: flex;

  margin-left: 70px;
}

.contat2 {
  width: 50%;
  height: 100%;

}

.contat2 h1 {
  text-align: center;
  color: #5e4e6d;
}

.contat2 p {
  font-size: 20px;
  color: black;
  margin-top: 20px;
}

.maps {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}

.gallery {
  width: 100%;
  height: auto;

  display: flex;
  margin-top: 20px;
}

.gallery2 {
  width: 30%;
  height: 100%;
  margin-left: 30px;
}

.gallery2 img {
  width: 100%;
  height: 360px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery2 img:hover {
  transform: scale(1.05); /* Slightly enlarges the image */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Adds a shadow on hover */
}
.desiese {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
}

.desiese1 {
  width: 60%;
  height: auto;
}

.desiese1 h1 {
  margin-left: 60px;
  font-size: 25px;
  margin-top: 20px;
}

.desiese1 p {
  margin-left: 60px;
  font-size: 15px;
}

.desiese1 li {
  margin-left: 60px;
}

.desiese2 {
  width: 19%;
  height: auto;
  background-color: #ff86ad;
  margin-left: 10px;
}

.desiese2 h1 {
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
  color: white;
}

.desiese2 h2 {
  font-size: 16px;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  color: white;
}

.desiese2 p {
  margin-left: 20px;
  font-size: 16px;
  color: white;
}


.desiese3 {
  width: 19%;
  height: auto;
  background-color: #ff86ad;
  margin-left: 10px;
}

.desiese3 h1 {
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
  color: white;
}


.desiese3 h2 {
  font-size: 16px;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  color: white;
}

.desiese3 p {
  margin-left: 20px;
  font-size: 16px;
  color: white;
}




.sticky-vertical-button {
  position: fixed;
  top: 40%;
  right: 0;
  transform: translateY(-50%);
  background-color: #007bff;
  padding: 10px;
  border-radius: 10px 0 0 10px;
  z-index: 1000;
  text-align: center;
  color: white;
  font-weight: bold;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.sticky-vertical-button:hover {
  background-color: black;
}

.sticky-vertical-button a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}


.popup-form {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 320px;
}

/* Form fields */
.popup-form input, .popup-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.popup-form textarea {
  resize: none;
  height: 100px;
}

/* Submit button */
.popup-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.popup-form button:hover {
  background-color: #0056b3;
}

/* Target the popup when its ID is in the URL */
#contact-form:target {
  display: block;
}

/* Overlay */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Show the overlay when the form is targeted */
#contact-form:target ~ .overlay {
  display: block;
}

/* Close button */
.close-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 20px;
}


/* Container for social media buttons */
.social-media-vertical {
  position: fixed;
  top: 50%;
  left: 0; /* Stick to the right side */
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.social-media-vertical a {
  background-color: #007bff; /* Default background color */
  color: white; /* Icon color */
  text-align: center;
  padding: 10px;
  margin: 5px 0; /* Space between buttons */
  border-radius: 10px 0 0 10px; /* Rounded edges on the left */
  text-decoration: none;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.social-media-vertical a:hover {
  background-color: #0056b3; /* Darker color on hover */
}

/* Facebook specific styles */
.social-media-vertical a.facebook {
  background-color: #3b5998;
}

.social-media-vertical a.facebook:hover {
  background-color: #2d4373;
}

/* Instagram specific styles */
.social-media-vertical a.instagram {
  background-color: #e4405f;
}

.social-media-vertical a.instagram:hover {
  background-color: #c13584;
}

/* YouTube specific styles */
.social-media-vertical a.youtube {
  background-color: #ff0000;
}

.social-media-vertical a.youtube:hover {
  background-color: #cc0000;
}

/* Icon styling */
.social-media-vertical i {
  font-size: 20px;
}



.video-vertical-button {
  position: fixed;
  top: 65%; /* Align vertically centered */
  right: 0; /* Stick to the right */
  transform: translateY(-50%);
  background-color: #28a745; /* Button background color */
  color: white;
  padding: 12px;
  border-radius: 10px 0 0 10px; /* Rounded left side */
  writing-mode: vertical-rl; /* Vertical text */
  text-orientation: mixed; /* Makes text readable vertically */
  font-weight: bold;
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

/* Hover effect */
.video-vertical-button:hover {
  background-color: #218838; /* Darker shade on hover */
}


@media screen and (min-width: 300px) and (max-width: 600px) {
  .main {
    width: 100%;
    height: 100%;
  }


  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 17px;
    margin-left: 0px;
    height: 52px;
  }

  .navbar {
    border: 1px solid #5e4e6d;
    width: 100%;
    margin-left: 0px;
    border-radius: 10px;

  }

  .nav-item:hover {
    background-color: #ff86ad;
    hyphens: 55px;
  }

  .container-fluid img {
   height: 100%;
   margin-top: 10px;
  }

  .carousel-item img {
    height: 220px;
  }

  .sections {
    width: 97%;
    height: auto;
    margin-left: 0px;
    display: flex;
    margin-top: 04px;
  }

  .sect2 {
    width: 30%;
    height: 100%;

  }

  .sect2 img {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-top: 50px;
  }

  .sect3 {
    width: 70%;
    height: 100%;

  }

  .sect3 h1 {
    color: #ff86ad;
    margin-left: 05px;
    font-weight: bold;
    font-size: 13px;
    margin-top: 20px;
    font-style: italic;

  }

  .about {
    width: 100%;
    height: auto;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .about1 {
    width: 100%;
    background-color: #ff86ad;
    height: 100%;
  }

  .about1 img {
    width: 95%;
    height: 100%;
    padding-left: 20px;
    margin-top: 20px;
  }

  .about2 {
    width: 100%;
    height: 100%;
  }

  .about2 h1 {
    margin-left: 15px;
    font-size: 28px;
    
  }




  .about2 h2 {
    margin-left: 15px;
    font-size: 19px;
    margin-top: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 h3 {
   margin-left: 15px;
    font-size: 22px;
    margin-top: 20px;
  }

  .about2 p {
    margin-left: 20px;
    font-size: 16px;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .about2 button {
    width: 150px;
    height: 50px;
    font-weight: bold;
    background-color: #ff86ad;
    border: none;
    margin-left: 40px;
    border-radius: 10px;
    margin-top: 05px;
  }

  .about2 button:hover {
    background-color: #5e4e6d;
    color: white;
  }

  .treatment {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-top: 20px;
  }

  .treat {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-left: 0px;
    color: white;
  }

  .treat h1 {
    padding-top: 20px;
  }

  .obstic {
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
  }

  .obstic2 {
    width: 100%;
    height: 100%;

  }

  .facs {
    width: 100%;
    height: 150px;

  }

  .facs h1 {
    margin-left: 105px;
    font-size: 22px;
    color: white;
    margin-top: 20px;
  }

  .facs p {
    font-size: 17px;
    color: white;
    margin-left: 100px;
    margin-top: -50px;
  }

  .facs img {
    margin-top: -40px;
  }

  .content {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    margin-left: 0px;
  }

  .content1 {
    width: 100%;
    height: 100%;

  }

  .content2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    background-image: url(img/moms.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .melo {
    width: 100%;
    height: 100%;
  }

  .melo h1 {
    font-size: 20px;
    text-align: center;
    color: white;
    margin-top: 20px;
  }

  .melo p {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-top: 30px;
    padding-left: 10px;
    text-shadow: 5px 5px 4px gray;
  }

  .content1 {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="email"],
  select {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

  }

  button:hover {
    background-color: #0056b3;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px #007bff;
  }

  .faq-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
  }

  .faq-item {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .faq-item h3 {
    font-size: 1em;
    color: lightcoral;
    font-weight: bold;
  }

  .faq-item p {
    font-size: 1em;
  }


  .footer {
    width: 100%;
    height: auto;
    background-color: lightgray;
  }

  .footer h1 {
    text-align: center;
    color: black;
    font-size: 32px;
    margin-top: 20px;
  }

  .footer1 {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
  }

  .foot {
    width: 100%;
    height: 100%;

    color: black;
  }

  .foot h1 {
    font-size: 22px;
  }

  .link {
    color: black;
    text-decoration: none;
    font-size: 18px;
    margin-top: 20px;
  }

  .foot p {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 17px;
  }

  .disclame {
    width: 100%;
    height: 60px;
    background-color: #ff86ad;
  }

  .disclame p {
    text-align: center;
    padding-top: 10px;
    color: white;
    font-size: 16px;
  }


  .heading {
    width: 100%;
    height: 110px;
    background-image: url(img/about-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .heading h1 {
    text-align: center;
    padding-top: 20px;
    color: white;
  }

  .heading p {
    text-align: center;
    color: white;
  }


  .contat {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .contat2 {
    width: 100%;
    height: 100%;

  }

  .contat2 h1 {
    text-align: center;
    color: #5e4e6d;
    margin-left: 10px;
  }

  .contat2 p {
    font-size: 20px;
    color: black;
    margin-top: 20px;
    margin-left: 10px;
  }

  .maps {
    width: 95%;
    height: 300px;
    margin-top: 10px;
    margin-left: 10px;
  }

  .gallery {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
    margin-top: 20px;
  }

  .gallery2 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .gallery2 img {
    width: 100%;
  }


  .desiese {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .desiese1 {
    width: 100%;
    height: auto;
  }

  .desiese1 h1 {
    margin-left: 20px;
    font-size: 25px;
    margin-top: 20px;
  }

  .desiese1 p {
    margin-left: 20px;
    font-size: 15px;
  }

  .desiese1 li {
    margin-left: 20px;
  }

  .desiese2 {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 0px;
  }

  .desiese2 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }

  .desiese2 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }

  .desiese2 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }


  .desiese3 {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 0px;
  }

  .desiese3 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }


  .desiese3 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }

  .desiese3 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }

}

@media screen and (min-width: 601px) and (max-width: 1250px) {
  .main {
    width: 1250px;
    height: 100%;
  }
  
  .navbar-brand,
  .navbar-nav .nav-link {
    color: white;
    font-size: 15px;
    margin-left: 30px;
    height: 50px;
    font-weight: bold;
   
  }
  
  .navbar-brand b {
    color: white;
  }
  
  .navigate{
    background: linear-gradient(90deg, #0078bf, #5a35aa, #b32491, #d82256);
  }
  
  .navbar {
  
  
    width: 98%;
    margin-left: 13px;
    border-radius: 10px;
  }
  
  
  
  
  .carousel-item img {
    height: 450px;
  }
  
  .sections {
    width: 90%;
    height: 300px;
    margin-left: 80px;
    display: flex;
    margin-top: 04px;
  }
  
  .sect2 {
    width: 30%;
    height: 100%;
  
  }
  
  .sect2 img {
    width: 200px;
    height: 200px;
    margin-left: 140px;
    margin-top: 40px;
  }
  
  .sect3 {
    width: 70%;
    height: 100%;
  
  }
  
  .sect3 h1 {
    color: #ff86ad;
    font-weight: bold;
    font-size: 25px;
    margin-top: 40px;
    font-style: italic;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    max-width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  
  
  .about {
    width: 90%;
    height: 500px;
    margin-left: 50px;
    display: flex;
    margin-top: 50px;
  }
  
  .about1 {
    width: 40%;
    background: linear-gradient(90deg, #0078bf, #5a35aa, #b32491, #d82256);
    height: 100%;
  }
  
  .about1 img {
    width: 92%;
    height: 90%;
    padding-left: 20px;
    margin-top: 20px;
  }
  
  .about2 {
    width: 60%;
  
    height: 100%;
  }
  
  .about2 h1 {
    margin-left: 120px;
    font-size: 25px;
  }
  
  .about2 h2 {
    margin-left: 0px;
    font-size: 19px;
    margin-top: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  
  .about2 h3 {
    margin-left: 120px;
    font-size: 22px;
  }
  
  .about2 p {
    margin-left: 120px;
    font-size: 16px;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  
  .about2 button {
    width: 150px;
    height: 45px;
    font-weight: bold;
    background: linear-gradient(90deg, #0078bf, #5a35aa, #b32491, #d82256);
    border: none;
    margin-left: 150px;
    border-radius: 10px;
    margin-top: 30px;
  }
  
  .about2 button:hover {
    background-color: #5e4e6d;
    color: white;
  }
  
  .treatment {
    width: 100%;
    height: auto;
    background-color: #ff86ad;
    margin-top: 20px;
  }
  
  .treat {
    width: 60%;
    height: 100%;
    text-align: center;
    margin-left: 250px;
    color: white;
  }
  
  .treat h1 {
    padding-top: 20px;
  }
  
  .obstic {
    width: 80%;
    height: auto;
  
    margin-left: 120px;
    margin-top: 30px;
    display: flex;
  }
  
  .obstic2 {
    width: 50%;
    height: 100%;
  
  }
  
  .facs {
    width: 100%;
    height: 150px;
  
  }
  
  .facs h1 {
    margin-left: 120px;
    font-size: 22px;
    color: white;
    margin-top: 20px;
  }
  
  
  
  .facs h1:hover{
    color: black;
    font-size: 25px;
    font-weight: bold;
  }
  
  .facs p {
    font-size: 17px;
    color: white;
    margin-left: 120px;
    margin-top: -50px;
  }
  
  .facs p:hover{
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
  .facs img {
    margin-top: -40px;
  }
  
  .content {
    width: 95%;
    height: 470px;
    display: flex;
    margin-left: 40px;
  }
  
  .content1 {
    width: 30%;
    height: 100%;
  
  }
  
  .content2 {
    width: 68%;
    height: 100%;
    margin-left: 15px;
    background-image: url(img/moms.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .melo {
    width: 450px;
    height: 100%;
  }
  
  .melo h1 {
    font-size: 20px;
    text-align: center;
    color: white;
    margin-top: 20px;
  }
  
  .melo p {
    color: white;
    font-size: 20px;
    margin-top: 30px;
    padding-left: 10px;
    text-shadow: 5px 5px 4px gray;
  }
  
  .content1 {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  select {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px #007bff;
  }
  .frequently{
    width: 100%;
    height: 100%;
    background-color: lightblue;
  }
  .faq-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
  }
  
  .faq-item {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .faq-item h3 {
    font-size: 1.2em;
    color: lightcoral;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  
  .faq-item p {
    font-size: 1em;
    color:black ;
    font-weight: bold;
  }
  
  
  .footer {
    width: 100%;
    height: 430px;
    background-color: lightgray;
  }
  
  .footer h1 {
  
    color: black;
    font-size: 25px;
    margin-top: 20px;
  }
  
  .footer1 {
    width: 100%;
    height: 350px;
    display: flex;
    padding-top: 30px;
  }
  
  .foot {
    width: 25%;
    height: 100%;
    color: black;
  }
  
  .foot h1 {
    font-size: 22px;
    margin-left: 40px;
  }
  
  .link {
    color: black;
    text-decoration: none;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .foot p {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-align: justify;
    width: 80%;
  }
  
  .disclame {
    width: 100%;
    height: 40px;
    background-color: #ff86ad;
  }
  
  .disclame p {
    text-align: center;
    padding-top: 10px;
    color: white;
  }
  
  .heading {
    width: 100%;
    height: 150px;
    background-image: url(img/about-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .heading h1 {
    text-align: center;
    padding-top: 30px;
    color: white;
  }
  
  .heading p {
    text-align: center;
    color: white;
  }
  
  .contat {
    width: 90%;
    height: 480px;
    display: flex;
  
    margin-left: 70px;
  }
  
  .contat2 {
    width: 50%;
    height: 100%;
  
  }
  
  .contat2 h1 {
    text-align: center;
    color: #5e4e6d;
  }
  
  .contat2 p {
    font-size: 20px;
    color: black;
    margin-top: 20px;
  }
  
  .maps {
    width: 100%;
    height: 400px;
    margin-top: 10px;
  }
  
  .gallery {
    width: 100%;
    height: auto;
  
    display: flex;
    margin-top: 20px;
  }
  
  .gallery2 {
    width: 30%;
    height: 100%;
    margin-left: 30px;
  }
  
  .gallery2 img {
    width: 100%;
  }
  
  
  .desiese {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
  }
  
  .desiese1 {
    width: 60%;
    height: auto;
  }
  
  .desiese1 h1 {
    margin-left: 60px;
    font-size: 25px;
    margin-top: 20px;
  }
  
  .desiese1 p {
    margin-left: 60px;
    font-size: 15px;
  }
  
  .desiese1 li {
    margin-left: 60px;
  }
  
  .desiese2 {
    width: 19%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 10px;
  }
  
  .desiese2 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }
  
  .desiese2 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }
  
  .desiese2 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }
  
  
  .desiese3 {
    width: 19%;
    height: auto;
    background-color: #ff86ad;
    margin-left: 10px;
  }
  
  .desiese3 h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
    color: white;
  }
  
  
  .desiese3 h2 {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
  }
  
  .desiese3 p {
    margin-left: 20px;
    font-size: 16px;
    color: white;
  }
}